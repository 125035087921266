<template>
	<div class="w-full relative">
		<div class="flex justify-between items-center">
			<h1 class="text-xl font-extrabold sm:text-2xl">Результаты НОК текущего года</h1>
			<div class="flex items-center">
				<div class="checkbox mr-2">
					<label class="flex"><input type="checkbox" @change="getRandom()" v-model="params.isRandom600" class="mr-2 check-big" />Получить выборку 600 анкет</label>
				</div>
				<Button text="Таблица для bus.gov.ru" color="white" @click.native="downloadBusGovExcel()" class="mr-2" />
				<Button text="Экспорт в Excel" icon="exel" @click.native="openExport = true" />
			</div>
		</div>
		<div class="table-respons t-overflow rounded-t-lg overflow-x-auto h-auto bg-white filter shadow-default" style="height: calc(100vh - 320px)">
			<table :style="yearResult.length > 0 ? 'min-height: 550px' : ''">
				<thead>
					<tr class="tr1__sticky">
						<th rowspan="3" style="min-width: 50px; padding: 0; background-color: #f9fafb" class="sticky-table__header1">П/П</th>
						<th rowspan="3" style="background-color: #f9fafb" class="th-sort w-32 sticky-table__header2">
							<div class="flex">
								<p @click="switchFilterVisibility(0)">Наименование МО</p>
								<Icons v-if="params.filters.find((f) => f.fieldName === fields[0].fieldName)" icon-name="filter" class="hidden sm:block ml-2" />
							</div>
							<FilterComponent
								v-on:change="switchFilterVisibility(null)"
								position="left"
								:filterOpen="filterOpen"
								:id-filter="0"
								:param="params"
								@query="getData"
								:fields="fields"
								:item="fields[0]"
							/>
						</th>
						<th rowspan="3">
							<p>Условия оказания</p>
						</th>
						<th rowspan="3" class="th-sort">
							<div class="flex">
								<p @click="switchFilterVisibility(2)">Итоговый результат по условиям оказания</p>
								<Icons v-if="params.filters.find((f) => f.fieldName === fields[2].fieldName)" icon-name="filter" class="hidden sm:block ml-2" />
							</div>
							<FilterComponent v-on:change="switchFilterVisibility(null)" :filterOpen="filterOpen" :id-filter="2" :param="params" @query="getData" :fields="fields" :item="fields[2]" />
						</th>
						<th rowspan="3" class="th-sort">
							<div class="flex">
								<p @click="switchFilterVisibility(3)">Количество анкет</p>
								<Icons v-if="params.filters.find((f) => f.fieldName === fields[3].fieldName)" icon-name="filter" class="hidden sm:block ml-2" />
							</div>
							<FilterComponent v-on:change="switchFilterVisibility(null)" :filterOpen="filterOpen" :id-filter="3" :param="params" @query="getData" :fields="fields" :item="fields[3]" />
						</th>
						<th colspan="20">
							<p>Показатели, характеризующие общие критерии оценки качества условий оказания услуг медицинскими организациями, в отношении которых проводится независимая оценка</p>
						</th>
					</tr>
					<tr class="tr2__sticky">
						<th colspan="4"><p>Открытость и доступность информации об организации</p></th>
						<th colspan="4"><p>Комфортность условий предоставления услуг, включая время ожидания предоставления медицинской услуги</p></th>
						<th colspan="4"><p>Доступность услуг для инвалидов</p></th>
						<th colspan="4"><p>Доброжелательность, вежливость работников медицинской организации</p></th>
						<th colspan="4"><p>Удовлетворенность условиями оказания услуг</p></th>
					</tr>
					<tr class="tr3__sticky">
						<th class="bg-lavender"><p>1</p></th>
						<th><p>1.1</p></th>
						<th><p>1.2</p></th>
						<th><p>1.3</p></th>
						<th class="bg-lavender"><p>2</p></th>
						<th><p>2.1</p></th>
						<th><p>2.2</p></th>
						<th><p>2.3</p></th>
						<th class="bg-lavender"><p>3</p></th>
						<th><p>3.1</p></th>
						<th><p>3.2</p></th>
						<th><p>3.3</p></th>
						<th class="bg-lavender"><p>4</p></th>
						<th><p>4.1</p></th>
						<th><p>4.2</p></th>
						<th><p>4.3</p></th>
						<th class="bg-lavender"><p>5</p></th>
						<th><p>5.1</p></th>
						<th><p>5.2</p></th>
						<th><p>5.3</p></th>
					</tr>
				</thead>
				<template v-for="(item, indx) in yearResult">
					<tbody @click="openRow(item)" :key="item.medicalOrgId" :class="{ 'bg-gray-30': item.medicalOrgId === opened }">
						<tr>
							<td rowspan="5" class="sticky-table__row1">
								<span v-if="params.currentPage === 0">
									{{ indx + 1 }}
								</span>
								<span v-if="params.currentPage > 0">
									{{ params.currentPage * rowsVisible + (indx + 1) }}
								</span>
							</td>
							<td rowspan="5" class="w-32 sticky-table__row2" :class="{ 'cursor-pointer': item.hasBranch }">
								<div class="flex items-center mb-2">
									<div v-if="item.isBranch" class="filial-or-secondary-default filial-bg">Филиал</div>
									<div v-if="item.isClone" class="filial-or-secondary-default secondary-bg ml-2">Вторичная МО</div>
								</div>
								<p :class="{ 'th-sort underline': item.hasBranch }">{{ item.name }}</p>
							</td>
							<td v-if="item.ambulator">А</td>
							<td v-if="item.ambulator">
								<p>{{ item.a_Result }}</p>
							</td>
							<td v-if="item.ambulator">
								<p>{{ item.a_Count }}</p>
							</td>
							<td v-if="item.ambulator" class="bg-lavender">
								<p>{{ item.a_1 }}</p>
							</td>
							<td v-if="item.ambulator">
								<p>{{ item.a_1_1 }}</p>
							</td>
							<td v-if="item.ambulator">
								<p>{{ item.a_1_2 }}</p>
							</td>
							<td v-if="item.ambulator">
								<p>{{ item.a_1_3 }}</p>
							</td>
							<td v-if="item.ambulator" class="bg-lavender">
								<p>{{ item.a_2 }}</p>
							</td>
							<td v-if="item.ambulator">
								<p>{{ item.a_2_1 }}</p>
							</td>
							<td v-if="item.ambulator">
								<p>{{ item.a_2_2 }}</p>
							</td>
							<td v-if="item.ambulator">
								<p>{{ item.a_2_3 }}</p>
							</td>
							<td v-if="item.ambulator" class="bg-lavender">
								<p>{{ item.a_3 }}</p>
							</td>
							<td v-if="item.ambulator">
								<p>{{ item.a_3_1 }}</p>
							</td>
							<td v-if="item.ambulator">
								<p>{{ item.a_3_2 }}</p>
							</td>
							<td v-if="item.ambulator">
								<p>{{ item.a_3_3 }}</p>
							</td>
							<td v-if="item.ambulator" class="bg-lavender">
								<p>{{ item.a_4 }}</p>
							</td>
							<td v-if="item.ambulator">
								<p>{{ item.a_4_1 }}</p>
							</td>
							<td v-if="item.ambulator">
								<p>{{ item.a_4_2 }}</p>
							</td>
							<td v-if="item.ambulator">
								<p>{{ item.a_4_3 }}</p>
							</td>
							<td v-if="item.ambulator" class="bg-lavender">
								<p>{{ item.a_5 }}</p>
							</td>
							<td v-if="item.ambulator">
								<p>{{ item.a_5_1 }}</p>
							</td>
							<td v-if="item.ambulator">
								<p>{{ item.a_5_2 }}</p>
							</td>
							<td v-if="item.ambulator">
								<p>{{ item.a_5_3 }}</p>
							</td>
						</tr>
						<tr v-if="item.stacionar">
							<td>C</td>
							<td>
								<p>{{ item.s_Result }}</p>
							</td>
							<td>
								<p>{{ item.s_Count }}</p>
							</td>
							<td class="bg-lavender">
								<p>{{ item.s_1 }}</p>
							</td>
							<td>
								<p>{{ item.s_1_1 }}</p>
							</td>
							<td>
								<p>{{ item.s_1_2 }}</p>
							</td>
							<td>
								<p>{{ item.s_1_3 }}</p>
							</td>
							<td class="bg-lavender">
								<p>{{ item.s_2 }}</p>
							</td>
							<td>
								<p>{{ item.s_2_1 }}</p>
							</td>
							<td>
								<p>{{ item.s_2_2 }}</p>
							</td>
							<td>
								<p>{{ item.s_2_3 }}</p>
							</td>
							<td class="bg-lavender">
								<p>{{ item.s_3 }}</p>
							</td>
							<td>
								<p>{{ item.s_3_1 }}</p>
							</td>
							<td>
								<p>{{ item.s_3_2 }}</p>
							</td>
							<td>
								<p>{{ item.s_3_3 }}</p>
							</td>
							<td class="bg-lavender">
								<p>{{ item.s_4 }}</p>
							</td>
							<td>
								<p>{{ item.s_4_1 }}</p>
							</td>
							<td>
								<p>{{ item.s_4_2 }}</p>
							</td>
							<td>
								<p>{{ item.s_4_3 }}</p>
							</td>
							<td class="bg-lavender">
								<p>{{ item.s_5 }}</p>
							</td>
							<td>
								<p>{{ item.s_5_1 }}</p>
							</td>
							<td>
								<p>{{ item.s_5_2 }}</p>
							</td>
							<td>
								<p>{{ item.s_5_3 }}</p>
							</td>
						</tr>
						<tr v-if="item.healthResort">
							<td>К</td>
							<td>
								<p>{{ item.k_Result }}</p>
							</td>
							<td>
								<p>{{ item.k_Count }}</p>
							</td>
							<td class="bg-lavender">
								<p>{{ item.k_1 }}</p>
							</td>
							<td>
								<p>{{ item.k_1_1 }}</p>
							</td>
							<td>
								<p>{{ item.k_1_2 }}</p>
							</td>
							<td>
								<p>{{ item.k_1_3 }}</p>
							</td>
							<td class="bg-lavender">
								<p>{{ item.k_2 }}</p>
							</td>
							<td>
								<p>{{ item.k_2_1 }}</p>
							</td>
							<td>
								<p>{{ item.k_2_2 }}</p>
							</td>
							<td>
								<p>{{ item.k_2_3 }}</p>
							</td>
							<td class="bg-lavender">
								<p>{{ item.k_3 }}</p>
							</td>
							<td>
								<p>{{ item.k_3_1 }}</p>
							</td>
							<td>
								<p>{{ item.k_3_2 }}</p>
							</td>
							<td>
								<p>{{ item.k_3_3 }}</p>
							</td>
							<td class="bg-lavender">
								<p>{{ item.k_4 }}</p>
							</td>
							<td>
								<p>{{ item.k_4_1 }}</p>
							</td>
							<td>
								<p>{{ item.k_4_2 }}</p>
							</td>
							<td>
								<p>{{ item.k_4_3 }}</p>
							</td>
							<td class="bg-lavender">
								<p>{{ item.k_5 }}</p>
							</td>
							<td>
								<p>{{ item.k_5_1 }}</p>
							</td>
							<td>
								<p>{{ item.k_5_2 }}</p>
							</td>
							<td>
								<p>{{ item.k_5_3 }}</p>
							</td>
						</tr>
						<tr v-if="item.psychiatric">
							<td>П</td>
							<td>
								<p>{{ item.p_Result }}</p>
							</td>
							<td>
								<p>{{ item.p_Count }}</p>
							</td>
							<td class="bg-lavender">
								<p>{{ item.p_1 }}</p>
							</td>
							<td>
								<p>{{ item.p_1_1 }}</p>
							</td>
							<td>
								<p>{{ item.p_1_2 }}</p>
							</td>
							<td>
								<p>{{ item.p_1_3 }}</p>
							</td>
							<td class="bg-lavender">
								<p>{{ item.p_2 }}</p>
							</td>
							<td>
								<p>{{ item.p_2_1 }}</p>
							</td>
							<td>
								<p>{{ item.p_2_2 }}</p>
							</td>
							<td>
								<p>{{ item.p_2_3 }}</p>
							</td>
							<td class="bg-lavender">
								<p>{{ item.p_3 }}</p>
							</td>
							<td>
								<p>{{ item.p_3_1 }}</p>
							</td>
							<td>
								<p>{{ item.p_3_2 }}</p>
							</td>
							<td>
								<p>{{ item.p_3_3 }}</p>
							</td>
							<td class="bg-lavender">
								<p>{{ item.p_4 }}</p>
							</td>
							<td>
								<p>{{ item.p_4_1 }}</p>
							</td>
							<td>
								<p>{{ item.p_4_2 }}</p>
							</td>
							<td>
								<p>{{ item.p_4_3 }}</p>
							</td>
							<td class="bg-lavender">
								<p>{{ item.p_5 }}</p>
							</td>
							<td>
								<p>{{ item.p_5_1 }}</p>
							</td>
							<td>
								<p>{{ item.p_5_2 }}</p>
							</td>
							<td>
								<p>{{ item.p_5_3 }}</p>
							</td>
						</tr>
						<tr class="row-total bold">
							<td>Итог</td>
							<td>
								<p>{{ item.r_Result }}</p>
							</td>
							<td>
								<p>{{ item.r_Count }}</p>
							</td>
							<td>
								<p>{{ item.r_1 }}</p>
							</td>
							<td>
								<p>{{ item.r_1_1 }}</p>
							</td>
							<td>
								<p>{{ item.r_1_2 }}</p>
							</td>
							<td>
								<p>{{ item.r_1_3 }}</p>
							</td>
							<td>
								<p>{{ item.r_2 }}</p>
							</td>
							<td>
								<p>{{ item.r_2_1 }}</p>
							</td>
							<td>
								<p>{{ item.r_2_2 }}</p>
							</td>
							<td>
								<p>{{ item.r_2_3 }}</p>
							</td>
							<td>
								<p>{{ item.r_3 }}</p>
							</td>
							<td>
								<p>{{ item.r_3_1 }}</p>
							</td>
							<td>
								<p>{{ item.r_3_2 }}</p>
							</td>
							<td>
								<p>{{ item.r_3_3 }}</p>
							</td>
							<td>
								<p>{{ item.r_4 }}</p>
							</td>
							<td>
								<p>{{ item.r_4_1 }}</p>
							</td>
							<td>
								<p>{{ item.r_4_2 }}</p>
							</td>
							<td>
								<p>{{ item.r_4_3 }}</p>
							</td>
							<td>
								<p>{{ item.r_5 }}</p>
							</td>
							<td>
								<p>{{ item.r_5_1 }}</p>
							</td>
							<td>
								<p>{{ item.r_5_2 }}</p>
							</td>
							<td>
								<p>{{ item.r_5_3 }}</p>
							</td>
						</tr>
					</tbody>
					<template v-if="item.medicalOrgId === opened">
						<tbody v-for="(subitem, ind1) in item.branches" :key="subitem.medicalOrgId" :class="{ 'bg-gray-30': item.medicalOrgId === opened }">
							<tr>
								<td rowspan="5" class="sticky-table__row1">
									{{ ind1 + 1 }}
								</td>
								<td rowspan="5" class="sticky-table__row2">
									<div class="flex items-center mb-2">
										<div v-if="subitem.isBranch" class="filial-or-secondary-default filial-bg">Филиал</div>
										<div v-if="subitem.isClone" class="filial-or-secondary-default secondary-bg ml-2">Вторичная МО</div>
									</div>
									<p>{{ subitem.name }}</p>
								</td>
								<td v-if="subitem.ambulator">А</td>
								<td v-if="subitem.ambulator">
									<p>{{ subitem.a_Result }}</p>
								</td>
								<td v-if="subitem.ambulator">
									<p>{{ subitem.a_Count }}</p>
								</td>
								<td v-if="subitem.ambulator" class="bg-lavender">
									<p>{{ subitem.a_1 }}</p>
								</td>
								<td v-if="subitem.ambulator">
									<p>{{ subitem.a_1_1 }}</p>
								</td>
								<td v-if="subitem.ambulator">
									<p>{{ subitem.a_1_2 }}</p>
								</td>
								<td v-if="subitem.ambulator">
									<p>{{ subitem.a_1_3 }}</p>
								</td>
								<td v-if="subitem.ambulator" class="bg-lavender">
									<p>{{ subitem.a_2 }}</p>
								</td>
								<td v-if="subitem.ambulator">
									<p>{{ subitem.a_2_1 }}</p>
								</td>
								<td v-if="subitem.ambulator">
									<p>{{ subitem.a_2_2 }}</p>
								</td>
								<td v-if="subitem.ambulator">
									<p>{{ subitem.a_2_3 }}</p>
								</td>
								<td v-if="subitem.ambulator" class="bg-lavender">
									<p>{{ subitem.a_3 }}</p>
								</td>
								<td v-if="subitem.ambulator">
									<p>{{ subitem.a_3_1 }}</p>
								</td>
								<td v-if="subitem.ambulator">
									<p>{{ subitem.a_3_2 }}</p>
								</td>
								<td v-if="subitem.ambulator">
									<p>{{ subitem.a_3_3 }}</p>
								</td>
								<td v-if="subitem.ambulator" class="bg-lavender">
									<p>{{ subitem.a_4 }}</p>
								</td>
								<td v-if="subitem.ambulator">
									<p>{{ subitem.a_4_1 }}</p>
								</td>
								<td v-if="subitem.ambulator">
									<p>{{ subitem.a_4_2 }}</p>
								</td>
								<td v-if="subitem.ambulator">
									<p>{{ subitem.a_4_3 }}</p>
								</td>
								<td v-if="subitem.ambulator" class="bg-lavender">
									<p>{{ subitem.a_5 }}</p>
								</td>
								<td v-if="subitem.ambulator">
									<p>{{ subitem.a_5_1 }}</p>
								</td>
								<td v-if="subitem.ambulator">
									<p>{{ subitem.a_5_2 }}</p>
								</td>
								<td v-if="subitem.ambulator">
									<p>{{ subitem.a_5_3 }}</p>
								</td>
							</tr>
							<tr v-if="subitem.stacionar">
								<td>C</td>
								<td>
									<p>{{ subitem.s_Result }}</p>
								</td>
								<td>
									<p>{{ subitem.s_Count }}</p>
								</td>
								<td class="bg-lavender">
									<p>{{ subitem.s_1 }}</p>
								</td>
								<td>
									<p>{{ subitem.s_1_1 }}</p>
								</td>
								<td>
									<p>{{ subitem.s_1_2 }}</p>
								</td>
								<td>
									<p>{{ subitem.s_1_3 }}</p>
								</td>
								<td class="bg-lavender">
									<p>{{ subitem.s_2 }}</p>
								</td>
								<td>
									<p>{{ subitem.s_2_1 }}</p>
								</td>
								<td>
									<p>{{ subitem.s_2_2 }}</p>
								</td>
								<td>
									<p>{{ subitem.s_2_3 }}</p>
								</td>
								<td class="bg-lavender">
									<p>{{ subitem.s_3 }}</p>
								</td>
								<td>
									<p>{{ subitem.s_3_1 }}</p>
								</td>
								<td>
									<p>{{ subitem.s_3_2 }}</p>
								</td>
								<td>
									<p>{{ subitem.s_3_3 }}</p>
								</td>
								<td class="bg-lavender">
									<p>{{ subitem.s_4 }}</p>
								</td>
								<td>
									<p>{{ subitem.s_4_1 }}</p>
								</td>
								<td>
									<p>{{ subitem.s_4_2 }}</p>
								</td>
								<td>
									<p>{{ subitem.s_4_3 }}</p>
								</td>
								<td class="bg-lavender">
									<p>{{ subitem.s_5 }}</p>
								</td>
								<td>
									<p>{{ subitem.s_5_1 }}</p>
								</td>
								<td>
									<p>{{ subitem.s_5_2 }}</p>
								</td>
								<td>
									<p>{{ subitem.s_5_3 }}</p>
								</td>
							</tr>
							<tr v-if="subitem.healthResort">
								<td>К</td>
								<td>
									<p>{{ subitem.k_Result }}</p>
								</td>
								<td>
									<p>{{ subitem.k_Count }}</p>
								</td>
								<td class="bg-lavender">
									<p>{{ subitem.k_1 }}</p>
								</td>
								<td>
									<p>{{ subitem.k_1_1 }}</p>
								</td>
								<td>
									<p>{{ subitem.k_1_2 }}</p>
								</td>
								<td>
									<p>{{ subitem.k_1_3 }}</p>
								</td>
								<td>
									<p>{{ subitem.k_2 }}</p>
								</td>
								<td class="bg-lavender">
									<p>{{ subitem.k_2_1 }}</p>
								</td>
								<td>
									<p>{{ subitem.k_2_2 }}</p>
								</td>
								<td>
									<p>{{ subitem.k_2_3 }}</p>
								</td>
								<td class="bg-lavender">
									<p>{{ subitem.k_3 }}</p>
								</td>
								<td>
									<p>{{ subitem.k_3_1 }}</p>
								</td>
								<td>
									<p>{{ subitem.k_3_2 }}</p>
								</td>
								<td>
									<p>{{ subitem.k_3_3 }}</p>
								</td>
								<td class="bg-lavender">
									<p>{{ subitem.k_4 }}</p>
								</td>
								<td>
									<p>{{ subitem.k_4_1 }}</p>
								</td>
								<td>
									<p>{{ subitem.k_4_2 }}</p>
								</td>
								<td>
									<p>{{ subitem.k_4_3 }}</p>
								</td>
								<td class="bg-lavender">
									<p>{{ subitem.k_5 }}</p>
								</td>
								<td>
									<p>{{ subitem.k_5_1 }}</p>
								</td>
								<td>
									<p>{{ subitem.k_5_2 }}</p>
								</td>
								<td>
									<p>{{ subitem.k_5_3 }}</p>
								</td>
							</tr>
							<tr v-if="subitem.psychiatric">
								<td>П</td>
								<td>
									<p>{{ subitem.p_Result }}</p>
								</td>
								<td>
									<p>{{ subitem.p_Count }}</p>
								</td>
								<td class="bg-lavender">
									<p>{{ subitem.p_1 }}</p>
								</td>
								<td>
									<p>{{ subitem.p_1_1 }}</p>
								</td>
								<td>
									<p>{{ subitem.p_1_2 }}</p>
								</td>
								<td>
									<p>{{ subitem.p_1_3 }}</p>
								</td>
								<td class="bg-lavender">
									<p>{{ subitem.p_2 }}</p>
								</td>
								<td>
									<p>{{ subitem.p_2_1 }}</p>
								</td>
								<td>
									<p>{{ subitem.p_2_2 }}</p>
								</td>
								<td>
									<p>{{ subitem.p_2_3 }}</p>
								</td>
								<td class="bg-lavender">
									<p>{{ subitem.p_3 }}</p>
								</td>
								<td>
									<p>{{ subitem.p_3_1 }}</p>
								</td>
								<td>
									<p>{{ subitem.p_3_2 }}</p>
								</td>
								<td>
									<p>{{ subitem.p_3_3 }}</p>
								</td>
								<td class="bg-lavender">
									<p>{{ subitem.p_4 }}</p>
								</td>
								<td>
									<p>{{ subitem.p_4_1 }}</p>
								</td>
								<td>
									<p>{{ subitem.p_4_2 }}</p>
								</td>
								<td>
									<p>{{ subitem.p_4_3 }}</p>
								</td>
								<td class="bg-lavender">
									<p>{{ subitem.p_5 }}</p>
								</td>
								<td>
									<p>{{ subitem.p_5_1 }}</p>
								</td>
								<td>
									<p>{{ subitem.p_5_2 }}</p>
								</td>
								<td>
									<p>{{ subitem.p_5_3 }}</p>
								</td>
							</tr>
							<tr class="row-total bold">
								<td>Итог</td>
								<td>
									<p>{{ subitem.r_Result }}</p>
								</td>
								<td>
									<p>{{ subitem.r_Count }}</p>
								</td>
								<td>
									<p>{{ subitem.r_1 }}</p>
								</td>
								<td>
									<p>{{ subitem.r_1_1 }}</p>
								</td>
								<td>
									<p>{{ subitem.r_1_2 }}</p>
								</td>
								<td>
									<p>{{ subitem.r_1_3 }}</p>
								</td>
								<td>
									<p>{{ subitem.r_2 }}</p>
								</td>
								<td>
									<p>{{ subitem.r_2_1 }}</p>
								</td>
								<td>
									<p>{{ subitem.r_2_2 }}</p>
								</td>
								<td>
									<p>{{ subitem.r_2_3 }}</p>
								</td>
								<td>
									<p>{{ subitem.r_3 }}</p>
								</td>
								<td>
									<p>{{ subitem.r_3_1 }}</p>
								</td>
								<td>
									<p>{{ subitem.r_3_2 }}</p>
								</td>
								<td>
									<p>{{ subitem.r_3_3 }}</p>
								</td>
								<td>
									<p>{{ subitem.r_4 }}</p>
								</td>
								<td>
									<p>{{ subitem.r_4_1 }}</p>
								</td>
								<td>
									<p>{{ subitem.r_4_2 }}</p>
								</td>
								<td>
									<p>{{ subitem.r_4_3 }}</p>
								</td>
								<td>
									<p>{{ subitem.r_5 }}</p>
								</td>
								<td>
									<p>{{ subitem.r_5_1 }}</p>
								</td>
								<td>
									<p>{{ subitem.r_5_2 }}</p>
								</td>
								<td>
									<p>{{ subitem.r_5_3 }}</p>
								</td>
							</tr>
						</tbody>
					</template>
				</template>
			</table>
			<div v-if="!loadingTable && yearResult.length === 0" class="flex items-center w-full h-60 whitespace-no-wrap text-xl leading-5 text-gray-500 text-center">
				<span class="w-full">Данных нет...</span>
			</div>
			<div v-if="loadingTable" class="absolute w-full h-full preloader rounded-lg">
				<loadingComponent class="absolute top-1/2 left-1/2" />
			</div>
		</div>
		<Pagination :rowsCount="totalRows" :pageSize="rowsVisible" :pageNumber="params.currentPage" :pagesCount="totalCount" @query="updatePage" />

		<PopUp :open="openExport" size="700px" title="Выберите поля для экспорта" @close="closeOpenExport">
			<div class="t-overflow flex flex-col overflow-auto" style="height: 850px">
				<CheckboxGroup :options="fieldsDownloadExcell" :disabled="false" />
			</div>
			<div class="flex mt-5 justify-end">
				<Button size="smoll" class="mr-3" text="Экспорт" :load="loadToExcell" :disabled="loadToExcell" @click.native="upLoadExcel(params, fieldsDownloadExcell)" />
				<Button size="smoll" color="white" text="Закрыть" @click.native="closeOpenExport()" />
			</div>
		</PopUp>
	</div>
</template>

<script>
import Button from '@/components/Button.vue';
import FilterComponent from '@/components/Filter.vue';
import Pagination from '@/components/Pagination.vue';
import Icons from '@/components/Icons.vue';
// Глобальные данные стора
import { getters, actions, mutations, methods } from '@/store/store';
// Локальные данные стора
import loadingComponent from '@/components/Loading.vue';
import actionsLocal from '../store/actions';
import PopUp from '@/components/PopUp.vue';
import CheckboxGroup from '@/components/CheckboxGroup.vue';

export default {
	name: 'TableProtocols',
	components: {
		loadingComponent,
		Button,
		FilterComponent,
		Pagination,
		PopUp,
		CheckboxGroup,
		Icons
	},
	data() {
		return {
			rowsVisible: this.$DefaultPageSize,
			totalRows: 0,
			totalCount: 0,
			openExport: false,
			openDelet: false,
			page: 0,
			allRows: [],
			yearResult: [],
			open: false,
			filterOpen: null,
			filterBy: null,
			popupTitle: '',
			params: {
				filters: [
					{
						fieldName: 'RegionId',
						filterType: 1,
						filterValueType: 1,
						value1: ''
					}
				],
				sortOption: {
					fieldName: '',
					sortType: null
				},
				pageSize: this.$MaxPageSize,
				currentPage: 0,
				isRandom600: false,
				columns: []
			},
			fields: [
				{
					Name: 'Наименование МО',
					fieldName: 'name',
					filterType: 2,
					filterValueType: 1,
					autocomplete: true,
					list: [],
					widthStroke: 'w-244',
					heightUl: '150px',
					itemKey: 'name',
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию (А-Я)',
					label2: 'По убыванию (Я-А)'
				},
				{
					Name: 'Условия оказания',
					fieldName: 'r_Result',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Итоговый результат по условиям оказания',
					fieldName: 'r_Result',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Количество анкет',
					fieldName: 'r_Count',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				}
			],
			fieldsDownloadExcell: [
				{ title: 'Филиал', id: 'Branch', checked: true },
				{ title: 'Наименование МО', id: 'Name', checked: true },
				{ title: 'Условия оказания', id: 'MOCondition', checked: true },
				{ title: 'Итоговый результат по условиям оказания', id: 'EachResult', checked: true },
				{ title: 'Количество анкет', id: 'EachCount', checked: true },
				{ title: 'Открытость и доступность информации об организации: 1', id: '1', checked: true },
				{ title: 'Открытость и доступность информации об организации: 1.1', id: '1_1', checked: true },
				{ title: 'Открытость и доступность информации об организации: 1.2', id: '1_2', checked: true },
				{ title: 'Открытость и доступность информации об организации: 1.3', id: '1_3', checked: true },
				{ title: 'Комфортность условий предоставления услуг, включая время ожидания предоставления медицинской услуги: 2', id: '2', checked: true },
				{ title: 'Комфортность условий предоставления услуг, включая время ожидания предоставления медицинской услуги: 2.1', id: '2_1', checked: true },
				{ title: 'Комфортность условий предоставления услуг, включая время ожидания предоставления медицинской услуги: 2.2', id: '2_2', checked: true },
				{ title: 'Комфортность условий предоставления услуг, включая время ожидания предоставления медицинской услуги: 2.3', id: '2_3', checked: true },
				{ title: 'Доступность услуг для инвалидов: 3', id: '3', checked: true },
				{ title: 'Доступность услуг для инвалидов: 3.1', id: '3_1', checked: true },
				{ title: 'Доступность услуг для инвалидов: 3.2', id: '3_2', checked: true },
				{ title: 'Доступность услуг для инвалидов: 3.3', id: '3_3', checked: true },
				{ title: 'Доброжелательность, вежливость работников медицинской организации: 4', id: '4', checked: true },
				{ title: 'Доброжелательность, вежливость работников медицинской организации: 4.1', id: '4_1', checked: true },
				{ title: 'Доброжелательность, вежливость работников медицинской организации: 4.2', id: '4_2', checked: true },
				{ title: 'Доброжелательность, вежливость работников медицинской организации: 4.3', id: '4_3', checked: true },
				{ title: 'Удовлетворенность условиями оказания услуг: 5', id: '5', checked: true },
				{ title: 'Удовлетворенность условиями оказания услуг: 5.1', id: '5_1', checked: true },
				{ title: 'Удовлетворенность условиями оказания услуг: 5.2', id: '5_2', checked: true },
				{ title: 'Удовлетворенность условиями оказания услуг: 5.3', id: '5_3', checked: true }
			],
			opened: null,
			loadToExcell: false,
			loadingTable: false
		};
	},
	computed: {
		...getters
	},
	methods: {
		...mutations,
		...actions,
		...actionsLocal,
		...methods,
		getRandom() {
			this.getData(this.params, true);
		},
		openRow(item) {
			if (item.hasBranch) {
				if (this.opened === item.medicalOrgId) {
					this.opened = null;
				} else {
					this.opened = item.medicalOrgId;
				}
			}
		},
		showMessages(text, type) {
			this.$toast[type](text, {
				position: 'top-right',
				timeout: 5000,
				closeOnClick: true,
				pauseOnFocusLoss: true,
				pauseOnHover: true,
				draggable: true,
				draggablePercent: 0.6,
				showCloseButtonOnHover: false,
				hideProgressBar: false,
				closeButton: 'button',
				icon: true,
				rtl: false
			});
		},
		upLoadExcel(params, fieldsDownloadExcell) {
			this.loadToExcell = true;
			let columnList = fieldsDownloadExcell.filter((f) => f.checked).map((m) => m.id);

			this.$http
				.post(
					`MedicalOrgs/GetResultForYearExcel?isRandom600=${params.isRandom600}`,
					{
						...params,
						columns: columnList
					},
					{
						responseType: 'blob',
						headers: {
							'Access-Control-Expose-Headers': 'Content-Disposition'
						}
					}
				)
				.then((res) => {
					let fileNameAndType = decodeURI(`${this.region.name}_Результаты НОК текущего года.xlsx`);

					const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', fileNameAndType);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
					this.loadToExcell = false;
					this.closeOpenExport();
				})
				.catch((error) => {
					if (error) {
						this.loadToExcell = false;
						this.showMessages('Ошибка выгрузки, обратитесь к Администратору!', 'error');
					}
				});
		},
		downloadBusGovExcel() {
			this.loadToExcell = true;

			this.$http
				.post(
					`MedicalOrgs/GetBusGovRuExcel?RegionId=${this.region.id}&Year=${this.getYears.currentYear}`,
					{},
					{
						responseType: 'blob',
						headers: {
							'Access-Control-Expose-Headers': 'Content-Disposition'
						}
					}
				)
				.then((res) => {
					let fileNameAndType = decodeURI(`${this.region.name}_${this.$moment().format('YYYY')}_Таблица для busgov.xlsx`);

					const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', fileNameAndType);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
					this.loadToExcell = false;
				});
		},
		switchFilterVisibility(key) {
			this.filterOpen = this.filterOpen === key ? null : key;
			this.filterBy = key;
		},
		updatePage(input) {
			this.rowsVisible = input.pageSize;
			this.params.currentPage = input.pageNumber;
			this.getData(this.params, false);
		},
		getData(params, resetPage) {
			if (resetPage) {
				this.params.currentPage = 0;

				this.addManualSearchByName(this.params.filters);

				this.loadingTable = true;

				this.getResultForYearByRegion(this.params).then((res) => {
					this.allRows = res.data.data;
					this.yearResult = this.getTablePage(this.allRows, this.params.currentPage, this.rowsVisible);
					this.fields[0].list = res.data.searchData;

					this.totalRows = res.data.totalRecords;
					this.totalCount = this.rowsVisible ? Math.ceil(res.data.totalRecords / this.rowsVisible) : 1;
					this.loadingTable = false;
				});
			} else {
				this.yearResult = this.getTablePage(this.allRows, this.params.currentPage, this.rowsVisible);
			}
		},
		closeOpenExport() {
			for (let i = 0; i < this.fieldsDownloadExcell.length; i += 1) {
				this.fieldsDownloadExcell[i].checked = true;
			}

			this.openExport = false;
		}
	},
	mounted() {
		this.params.filters[0].value1 = this.region.id;

		this.getRegionYearData(this.region.id).then(() => {
			this.params.filters.push({
				fieldName: 'YearStr',
				filterType: 1,
				filterValueType: 1,
				value1: this.getYears.currentYear.toString(),
				value2: ''
			});
			this.getData(this.params, true);
		});
	}
};
</script>

<style scoped>
.tr1__sticky {
	background-color: #f9fafb;
	top: 0;
	position: sticky;
	z-index: 7;
}
.tr2__sticky {
	background-color: #f9fafb;
	top: 60px;
	position: sticky;
	z-index: 6;
}
.tr3__sticky {
	background-color: #f9fafb;
	top: 181px;
	position: sticky;
	z-index: 6;
}
.sticky-table__header1 {
	left: 0;
	z-index: 9;
	position: sticky !important;
	text-align: center;
	/* background-color: rgba(255, 255, 255); */
}

.sticky-table__row1 {
	left: 0;
	z-index: 1;
	position: sticky !important;
	background-color: rgba(255, 255, 255);
}
.sticky-table__header2 {
	left: 49px;
	z-index: 9;
	position: sticky !important;
	text-align: center;
	/* background-color: rgba(255, 255, 255); */
}

.sticky-table__row2 {
	left: 69px;
	z-index: 1;
	position: sticky !important;
	background-color: rgba(255, 255, 255);
}

.table-respons td,
.table-respons th {
	padding: 20px 30px;
	vertical-align: center;
	text-align: center;
	border-bottom: 1px solid #f2f5ff;
	border-right: 1px solid #f2f5ff;
	position: relative;
}

th {
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	color: #6a6e83;
}

td {
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #6a6e83;
}

.table-respons {
	min-height: 385px;
	position: relative;
	overflow: auto;
	width: 100%;
	margin-top: 20px;
}

.table-respons .th-sort {
	text-decoration-line: underline;
	cursor: pointer;
}

.bold td {
	font-weight: 600;
}

.table-respons {
	background: #fff;
	border-radius: 10px 10px 0 0;
	/* box-shadow: 0px 4px 17px rgba(171, 184, 225, 0.4); */
}

.table-respons::-webkit-scrollbar {
	width: 11px; /* ширина всей полосы прокрутки */
}

.table-respons::-webkit-scrollbar-track {
	background: rgba(168, 177, 206, 0.3); /* цвет зоны отслеживания */
}

.table-respons::-webkit-scrollbar-thumb {
	background: #3377ff;
	border-radius: 10px;
}

.row-total,
.row-total td {
	background-color: #e2efda;
}
</style>
